import { useState } from 'react';
import { useNotify, Button, useRecordContext, useDataProvider } from 'react-admin';

const PasswordResetButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    try {
      await dataProvider.resetPassword(record.id, 'users');
      notify('Request sent successfully.');
    } catch (error) {
      notify('Failed: Please try again.');
    }
    setLoading(false);
  };
  return <Button label="Reset Password" onClick={handleClick} disabled={loading} />;
};

export default PasswordResetButton;
