import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin';
import { MenuActionTypeChoices, TestTypeChoices } from '../../types';

export const MobileVersionCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="AppBundleID" label="App ID / Bundle ID" required={true} fullWidth={true} />
      <TextInput source="Version" label={'Minimum App Version'} required={true} type="number" />
      <TextInput
        source="TestAuthorizationUrl"
        label={'Test Authorization Url'}
        required={true}
        type="url"
        fullWidth={true}
      />
      <CheckboxGroupInput
        source="AllowedTestTypes"
        label="Allowed Test Types"
        // required={true}
        choices={TestTypeChoices}
      />
      <TextInput source="AppStoreUrl" label={'Apple App Store Url'} type="url" fullWidth={true} />
      <TextInput
        label={'Google Play Store Url'}
        source="PlayStoreUrl"
        type="url"
        fullWidth={true}
      />
      <TextInput
        label={'Quidel Barocde API Hostname'}
        source="BarcodeApiHostname"
        fullWidth={true}
      />
      <TextInput label={'Quidel Barocde APIKEY'} source="BarcodeApiKey" style={{ width: '50%' }} />
      <ArrayInput source="AppSettings" label="App Configuration Settings">
        <SimpleFormIterator inline>
          <TextInput source="name" required={true} />
          <TextInput source="value" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="AuthorizationUrls">
        <SimpleFormIterator disableReordering={true}>
          <SelectInput
            source="TestType"
            label="Test Type"
            required={true}
            choices={TestTypeChoices}
          />
          <TextInput
            source="DocumentUrl"
            label="Test Authroization Url (TBD3.1)"
            required={true}
            fullWidth={true}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="SupportMenuItems">
        <SimpleFormIterator>
          <SelectInput
            source="ActionType"
            label="Menu Action"
            required={true}
            choices={MenuActionTypeChoices}
          />
          <TextInput source="IconName" label="Menu Icon" />
          <TextInput
            source="MenuLabel"
            label="Menu Label"
            required={true}
            style={{ width: '50%' }}
          />
          <TextInput
            source="MenuValue"
            label="Menu Value"
            required={true}
            style={{ width: '50%' }}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
