import { useRecordContext, TextFieldProps } from 'react-admin';

export function TestTypeField(props: TextFieldProps) {
  const record = useRecordContext() || {};

  let result = '';

  // traverse the source in to the object
  const parts = (props.source || '').split('.');
  const value: any = parts.reduce((obj: any, key: string) => {
    if (typeof obj === 'object' && obj !== null) {
      return obj[key];
    }

    return undefined;
  }, record);

  const testType = (value || '').toUpperCase();

  switch (testType) {
    case 'QCOVIDANTIGEN':
    case 'QCOVIDREADONLY':
      result = 'COVID-19';
      break;
    case 'QCOVIDFLU':
      result = 'COVID-19 & Flu';
      break;
    case 'QFLU':
      result = 'Flu';
      break;
    case 'QRSV':
      result = 'RSV';
      break;
    default:
      result = '* ' + record.TestType;
  }

  return record ? <span className={props.className}>{result}</span> : null;
}
