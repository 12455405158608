import {
  Edit,
  SimpleForm,
  SimpleShowLayout,
  required,
  TextField,
  TextInput,
  maxLength,
  DateField,
  CheckboxGroupInput,
} from 'react-admin';
import { ResearchAccessTypeChoices } from 'types';
import { APIKeyInput } from './components';

export const ResearchAccessEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <DateField source="CreatedAt" label="Created At" showTime={true} />
        <DateField source="UpdatedAt" label="Updated At" showTime={true} />
      </SimpleShowLayout>

      <TextInput
        source="Name"
        label="Organization Name"
        fullWidth
        validate={[required(), maxLength(400, 'Organization Name is limited to 400 characters')]}
      />
      <TextInput
        source="Details"
        label="Description"
        fullWidth
        validate={[required(), maxLength(8000, 'Organization Name is limited to 8000 characters')]}
      />
      <APIKeyInput source="APIKey" label="API Key" />
      <CheckboxGroupInput
        source="AccessTypes"
        label="Access To"
        choices={ResearchAccessTypeChoices}
      />
    </SimpleForm>
  </Edit>
);
