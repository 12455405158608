import { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BulkDeleteWithConfirmButton,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import { Chip } from '@mui/material';

export const TestAdVideoList = (props: any) => {
  const BulkActionButtons = () => {
    return (
      <Fragment>
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmContent="Are you sure you want to delete the selected Test Ad Videos?"
        />
      </Fragment>
    );
  };

  const KeywordTags = () => {
    const record = useRecordContext();
    return (record.Keywords || []).map((keyword: string) => (
      <Chip key={`kw-${keyword}`} label={keyword} />
    ));
  };

  return (
    <List {...props} title="System Messages" pagination={false} perPage={1000}>
      <Datagrid bulkActionButtons={<BulkActionButtons />}>
        <TextField source="Description" label="Description" />
        <WrapperField label="Keywords">
          <KeywordTags />
        </WrapperField>
        <TextField source="VideoUrl" label="Video Url" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
