import React, { FC, Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextFieldProps,
  NumberField,
  UrlField,
  FunctionField,
  EditButton,
  BulkDeleteWithConfirmButton,
  useRecordContext,
} from 'react-admin';
import { Chip } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

export const MobileVersionList = (props: any) => {
  const BulkActionButtons = () => {
    return (
      <Fragment>
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmContent="Are you sure you want to delete the selected Mobile Version entries?"
        />
      </Fragment>
    );
  };

  const AllowedTestTypeField: FC<TextFieldProps> = () => {
    const record = useRecordContext();
    return (record?.AllowedTestTypes || []).map((testType: string, idx: number) => {
      return <Chip key={`type-${idx}`} label={testType} />;
    });
  };

  return (
    <List {...props} bulkActionButtons={<BulkActionButtons />} title="Mobile Versions">
      <Datagrid>
        <TextField source="AppBundleID" label={'App ID / Bundle ID'} />
        <NumberField source="Version" label={'Minimum App Version'} />
        <UrlField source="TestAuthorizationUrl" label="Test Authorization Url (TBD3.1)" />
        <AllowedTestTypeField source="AllowedTestTypes" label={'Allowed Test Types'} />
        <FunctionField
          label="Store Urls Defined"
          render={(record: any) => {
            return (
              <>
                {!!record.AppStoreUrl ? <AppleIcon /> : null}
                {!!record.PlayStoreUrl ? <AndroidIcon /> : null}
              </>
            );
          }}
        />
        {/* <TextField source="BarcodeApiHostname" label="Quidel Barocde API Hostname" />
        <TextField source="BarcodeApiKey" label="Quidel Barocde APIKEY" /> */}
        <EditButton />
      </Datagrid>
    </List>
  );
};
