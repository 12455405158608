import React, { useState } from 'react';
import {
  Show,
  TextField,
  DateField,
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  useRecordContext,
  SelectField,
  BooleanField,
  NumberField,
} from 'react-admin';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Divider } from '@mui/material';

import { ImageComponent, ResultField, ResultTypeField } from '../Common';
import { ResearchCaptureModeChoices } from 'types';
import { ResearchTestTypeField } from './utils';

const classes = {
  uppercase: `ResearchResult-uppercase`,
};

const StyledShow = styled(Show)(({ theme: Theme }) => ({
  [`& .${classes.uppercase}`]: {
    textTransform: 'uppercase',
  },
}));

const Title = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Result #{record.SupportCode}</span>;
};

const ImageAnalysisResults = () => {
  const record = useRecordContext();

  return (
    <>
      <Grid item xs={12} mt={2}>
        <Typography variant="h6">Image Analysis Results</Typography>
        <Divider />
      </Grid>
      {record.Result ? (
        <Grid item xs={3}>
          <ArrayField source="Result" label="Test Results">
            <Datagrid bulkActionButtons={false}>
              <ResultTypeField className={classes.uppercase} label="Test Type" source="name" />
              <ResultField label="Result" source="value" />
            </Datagrid>
          </ArrayField>
        </Grid>
      ) : (
        <div style={{ padding: '1em', fontSize: '0.8em' }}>
          * No Image Analysis Results Available *
        </div>
      )}
    </>
  );
};

export const ResearchResultShow = (props: any) => {
  const [showImageProcessing] = useState<boolean>(false);

  return (
    <StyledShow {...props} title={<Title />}>
      <Grid container sx={{ padding: 2 }}>
        <Grid item xs={12} mt={2}>
          <Typography variant="h6">Test Capture Information</Typography>
          <Divider />
        </Grid>
        <Grid container sx={{ margin: 0 }}>
          <Grid item xs={4}>
            <ImageComponent source="ImageLocation" />
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <TextField label="Test ID" source="TestResult.SupportCode" />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <ResearchTestTypeField label="Test Type" source="TestResult.TestType" />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <TextField label="Session Code" source="SessionCode" emptyText="N/A" />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <NumberField label="Sequence Number" source="SequenceIndex" />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <SelectField
                    label="Capture Mode"
                    source="SessionJson.captureMode"
                    choices={ResearchCaptureModeChoices}
                    emptyText="* Undefined *"
                  />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <BooleanField
                    label="Manual Capture"
                    source="IsManualCapture"
                    valueLabelTrue="YES"
                    valueLabelFalse="NO"
                  />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <BooleanField
                    label="Is Sample Positive"
                    source="IsSamplePositive"
                    valueLabelTrue="YES"
                    valueLabelFalse="NO"
                    emptyText="UNDEFINED"
                  />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <DateField label="Captured At" source="TestResult.CreatedAt" />
                </SimpleShowLayout>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {showImageProcessing && <ImageAnalysisResults />}
        {showImageProcessing && (
          <>
            <Grid item xs={12} mt={2}>
              <Typography variant="h6">Image Processing Information</Typography>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <DateField label="Processed At" source="TestResult.ProcessedAt" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField label="Processing Duration" source="TestResult.ProcessingDuration" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={4}>
              <SimpleShowLayout>
                <TextField label="Image Analysis Revision" source="ImageAnalysisRevision" />
              </SimpleShowLayout>
            </Grid>
          </>
        )}

        <Grid item xs={12} mt={2}>
          <Typography variant="h6">Capture Device Information</Typography>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <SimpleShowLayout>
            <TextField label="Device ID" source="TestResult.DeviceInformation.deviceID" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Brand" source="TestResult.DeviceInformation.brand" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Model" source="TestResult.DeviceInformation.model" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="OS" source="TestResult.DeviceInformation.os" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="OS Version" source="TestResult.DeviceInformation.osVersion" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField
              label="Application Version"
              source="TestResult.DeviceInformation.applicationVersion"
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Is a Tablet" source="TestResult.DeviceInformation.isTablet" />
          </SimpleShowLayout>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography variant="h6">Recording Organization</Typography>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Org Id" source="ResearchAccess.id" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Org Name" source="ResearchAccess.Name" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </StyledShow>
  );
};
