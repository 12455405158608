import { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BulkDeleteWithConfirmButton,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import { Chip } from '@mui/material';

export const ResearchAccessList = (props: any) => {
  const BulkActionButtons = () => {
    return (
      <Fragment>
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmTitle="Delete Research Access"
          confirmContent="Are you sure you want to delete the selected Research Access entries?"
        />
      </Fragment>
    );
  };

  const AccessTypeTags = () => {
    const record = useRecordContext();
    return (record.AccessTypes || []).map((types: string) => (
      <Chip key={`kw-${types}`} label={types} />
    ));
  };

  return (
    <List {...props} title="Research Access" pagination={false} perPage={1000}>
      <Datagrid bulkActionButtons={<BulkActionButtons />}>
        <TextField source="Name" label="Organization Name" />
        <TextField source="Details" label="Description" />
        <TextField source="APIKey" label="API Key" />
        <WrapperField label="Access Types">
          <AccessTypeTags />
        </WrapperField>
        <EditButton />
      </Datagrid>
    </List>
  );
};
