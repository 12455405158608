import { fetchUtils } from 'react-admin';
import config from '../config';

const httpClient = (url: any, options = {}) => {
  // @ts-ignore
  if (!options.headers) {
    // @ts-ignore
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const lastLoggedUser = localStorage.getItem(
    `CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.LastAuthUser`
  );

  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.${lastLoggedUser}.idToken`
  );

  // @ts-ignore
  options.user = {
    authenticated: true,
    token,
  };

  return fetchUtils.fetchJson(url, options);
};

export default httpClient;
