import { useRecordContext, DateField } from 'react-admin';

export const CompletedAtField = ({ label }: { label: string }) => {
  const record = useRecordContext();
  const hasUserResult = record['UserResult'];

  if (hasUserResult) {
    return <DateField label={label} source="UserResult.recordedAt" showTime={true} />;
  }
  return <DateField label={label} source="CreatedAt" showTime={true} />;
};
