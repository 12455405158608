import PropTypes from 'prop-types';
import { Form, PasswordInput, useTranslate } from 'react-admin';
import { styled } from '@mui/material/styles';
import { Button, CardActions, CircularProgress } from '@mui/material';

const PREFIX = 'RaLoginForm';

const classes = {
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme: Theme }) => ({
  [`& .${classes.form}`]: {
    padding: '0 1em 1em 1em',
  },

  [`& .${classes.input}`]: {
    marginTop: '1em',
  },

  [`& .${classes.button}`]: {
    minWidth: '40%',
  },

  [`& .${classes.icon}`]: {
    marginRight: Theme.spacing(1),
  },
}));

interface Props {
  loading: boolean;
  onSubmit: (values: any) => void;
}

interface FormData {
  password: string;
}

const ChangePasswordForm = (props: Props) => {
  const { onSubmit, loading } = props;
  const translate = useTranslate();

  const validate = (values: FormData) => {
    const errors: { password?: string } = !values.password
      ? { password: translate('ra.validation.required') }
      : {};
    return errors;
  };

  return (
    <Form
      onSubmit={(values) => onSubmit(values)}
      validate={(values) => validate(values as FormData)}
    >
      <Root>
        <div className={classes.form}>
          <p>Please change your password to continue signing in.</p>
          <div className={classes.input}>
            <PasswordInput
              fullWidth
              id="password"
              source="password"
              label={translate('ra.auth.password')}
              required
              disabled={loading}
            />
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </div>
      </Root>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  redirectTo: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ChangePasswordForm;
