import {
  Create,
  SimpleForm,
  required,
  TextInput,
  maxLength,
  CheckboxGroupInput,
} from 'react-admin';
import { APIKeyInput } from './components';
import { ResearchAccessTypeChoices } from 'types';

export const ResearchAccessCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="Name"
          label="Organization Name"
          fullWidth
          validate={[required(), maxLength(400, 'Organization Name is limited to 400 characters')]}
        />
        <TextInput
          source="Details"
          label="Description"
          fullWidth
          validate={[
            required(),
            maxLength(8000, 'Organization Name is limited to 8000 characters'),
          ]}
        />
        <APIKeyInput source="APIKey" label="API Key" />
        <CheckboxGroupInput
          source="AccessTypes"
          label="Access To"
          choices={ResearchAccessTypeChoices}
        />
      </SimpleForm>
    </Create>
  );
};
