import {
  Create,
  SimpleForm,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  useGetList,
  Loading,
} from 'react-admin';
import { TestTypeChoices } from 'types';

export const TestAdVideoPlaylistCreate = (props: any) => {
  const { data, isLoading } = useGetList('test-ad-video', {
    pagination: { page: 1, perPage: 5000 },
    sort: { field: 'Description', order: 'ASC' },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput source="TestType" label="Test Type" choices={TestTypeChoices} />
        <BooleanInput source="IsEnabled" label="Is Enabled" />
        <ArrayInput source="VideoIds" label="Videos">
          <SimpleFormIterator>
            <SelectInput label="Select Video..." choices={data || []} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
