import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  EditProps,
  SaveButton,
  Toolbar,
  SelectInput,
} from 'react-admin';
import IconSend from '@mui/icons-material/Send';
import { AdminRoleChoices } from 'types';

export const AdminUserEdit = (props: EditProps) => {
  return (
    <Edit {...props} title="Edit Admin User">
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton label="Update" icon={<IconSend />} />
          </Toolbar>
        }
      >
        <TextInput disabled source="Name" validate={required('Name field is required.')} />
        <TextInput source="Email" disabled />
        <SelectInput
          source="UserRole"
          choices={AdminRoleChoices}
          validate={required('User role is required.')}
        />
      </SimpleForm>
    </Edit>
  );
};
