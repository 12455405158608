import React, { HtmlHTMLAttributes, createElement, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, Avatar } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
  useCheckAuth,
  useLogin,
  useNotify,
  useRedirect,
  TitleComponent,
  useDataProvider,
  defaultTheme,
  Notification,
} from 'react-admin';

import DefaultLoginForm from './LoginForm';
import ChangePasswordForm from './ChangePasswordForm';
import ConfirmPasswordForm, { ConfirmPasswordFormData } from './ConfirmPasswordForm';
import ForgotPasswordForm, { ForgotPasswordFormData } from './ForgotPasswordForm';
import * as R from 'ramda';
import { Auth } from 'aws-amplify';

const PREFIX = 'RaLogin';

const classes = {
  main: `${PREFIX}-main`,
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme: Theme }) => ({
  [`&.${classes.main}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
  },

  [`& .${classes.card}`]: {
    minWidth: 300,
    marginTop: '6em',
    padding: '0em 1em',
  },

  [`& .${classes.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.icon}`]: {
    backgroundColor: Theme.palette.secondary.main,
  },
}));

export const applyBinaryFn = (f: any) => R.apply(R.binary(f));

export const completeNewPassword = (params: any) =>
  applyBinaryFn(Auth.completeNewPassword.bind(Auth))(params);

export const changePassword = R.compose(completeNewPassword, R.props(['cognitoUser', 'password']));

const Login: React.FunctionComponent<LoginProps> = (props) => {
  const { theme, ...rest } = props;
  return <LoginView {...rest} />;
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
};

Login.defaultProps = {
  theme: defaultTheme,
  notification: Notification,
};

export default Login;

export interface LoginProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  backgroundImage?: string;
  classes?: object;
  className?: string;
  notification?: any;
  theme?: object;
  title?: TitleComponent;
}

type STEPS = 'login' | 'setPassword' | 'confirmPassword' | 'forgotPassword';

const LoginView: React.FunctionComponent<Omit<LoginProps, 'theme'>> = (props) => {
  const {
    title,
    classes: classesOverride,
    className,
    notification,
    backgroundImage,
    ...rest
  } = props;

  const dataProvider: any = useDataProvider();
  const [cognitoUser, setCognitoUser] = useState(null);
  const [cognitoUserID, setCognitoUserID] = useState<string>('');
  const [step, setStep] = useState<STEPS>('login');
  const [loading, setLoading] = useState<boolean>(false);
  const login = useLogin();
  const notify = useNotify();

  const checkAuth = useCheckAuth();
  const redirect = useRedirect();

  useEffect(() => {
    // authenticated user has been updated, check to see if the user is authorized
    checkAuth({}, false, undefined, true)
      .then(() => {
        // already authenticated, redirect to the home page
        redirect('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, redirect, cognitoUser]);

  let backgroundImageLoaded = false;

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded) {
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  const handleLoginSubmit = (values: any) => {
    setLoading(true);
    login(values, '/login')
      .then((user) => {
        setCognitoUser(user);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setStep('setPassword');
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          { type: 'warning' }
        );
      });
  };

  const handleChangePasswordSubmit = (values: any) => {
    setLoading(true);
    changePassword({
      password: values.password,
      cognitoUser: cognitoUser,
    })
      .then((result: any) => {
        setLoading(false);
        notify('Password is changed successfully.', { type: 'success' });

        redirect('/');
      })
      .catch((error: any) => {
        setLoading(false);

        if (typeof error === 'string' || error?.message) {
          notify(typeof error === 'string' ? error : error?.message);
        }
      });
  };

  const handleForgotPassword = () => {
    setStep('forgotPassword');
  };

  const handleForgotPasswordCancel = () => {
    setStep('login');
  };

  const handleForgotPasswordSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);

    const email = values.username || '';

    setCognitoUserID('');
    dataProvider
      .forgotPassword({ email })
      .then((response: any) => {
        const data = response.data || {};
        setCognitoUserID(data.id || '');
        setLoading(false);
        setStep('confirmPassword');
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const handleConfirmPasswordCancel = () => {
    setStep('login');
  };

  const handleConfirmPasswordSubmit = (values: ConfirmPasswordFormData) => {
    setLoading(true);

    const code = values.code || '';
    const password = values.password;

    dataProvider
      .confirmPassword({ userId: cognitoUserID, code, password })
      .then(() => {
        setCognitoUserID('');
        setLoading(false);
        notify('Password is changed successfully.', { type: 'success' });

        setStep('login');
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  return (
    <Root className={classnames(classes.main, className)} {...rest}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {step === 'login' && (
          <DefaultLoginForm
            onSubmit={handleLoginSubmit}
            onForgotPassword={handleForgotPassword}
            loading={!!loading}
          />
        )}
        {step === 'setPassword' && (
          <ChangePasswordForm onSubmit={handleChangePasswordSubmit} loading={!!loading} />
        )}
        {step === 'forgotPassword' && (
          <ForgotPasswordForm
            onSubmit={handleForgotPasswordSubmit}
            onCancel={handleForgotPasswordCancel}
            loading={!!loading}
          />
        )}
        {step === 'confirmPassword' && (
          <ConfirmPasswordForm
            onSubmit={handleConfirmPasswordSubmit}
            onCancel={handleConfirmPasswordCancel}
            loading={!!loading}
          />
        )}
      </Card>
      {notification ? createElement(notification) : null}
    </Root>
  );
};
