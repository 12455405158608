import simpleRestProvider from 'ra-data-simple-rest';
import {
  RaRecord,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
  GetListResult,
  GetListParams,
  withLifecycleCallbacks,
} from 'react-admin';
import httpClient from 'providers/httpClient';
import {
  ResultPDFConfig_create,
  ResultPDFConfig_update,
  TestAdVideo_list,
} from './dataProvider.custom';
import { CustomLifecycleCallbacks } from './dataProvider.lifecycle';

const baseDataProvider = simpleRestProvider(`${process.env.REACT_APP_API_URL}/admin`, httpClient);

const dataProvider = withLifecycleCallbacks(
  {
    ...baseDataProvider,
    getList: <RecordType extends RaRecord>(
      resource: string,
      params: GetListParams
    ): Promise<GetListResult<RecordType>> => {
      if (resource === 'test-ad-video') {
        return TestAdVideo_list(baseDataProvider, resource, params);
      }
      // fallback to the default implementation
      return baseDataProvider.getList(resource, params);
    },
    create: <RecordType extends RaRecord>(
      resource: string,
      params: CreateParams
    ): Promise<CreateResult<RecordType>> => {
      if (resource === 'result-pdf-config') {
        return ResultPDFConfig_create(baseDataProvider, resource, params);
      }
      // fallback to the default implementation
      return baseDataProvider.create(resource, params);
    },
    update: <RecordType extends RaRecord>(
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult<RecordType>> => {
      if (resource === 'result-pdf-config') {
        return ResultPDFConfig_update(baseDataProvider, resource, params);
      }

      // fallback to the default implementation
      return baseDataProvider.update(resource, params);
    },
    resetPassword: (userId: string, type: string) => {
      return httpClient(`${process.env.REACT_APP_API_URL}/admin/${type}/${userId}/reset/password`, {
        method: 'PUT',
      }).then(({ json }) => ({ data: json }));
    },
    forgotPassword: ({ email }: { email: string }) => {
      return httpClient(`${process.env.REACT_APP_API_URL}/admin/admins/forgot-password`, {
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      }).then(({ json }) => ({ data: json }));
    },
    confirmPassword: ({
      userId,
      code,
      password,
    }: {
      userId: string;
      code: string;
      password: string;
    }) => {
      return httpClient(
        `${process.env.REACT_APP_API_URL}/admin/admins/${userId}/confirm-password`,
        {
          method: 'POST',
          body: JSON.stringify({
            code,
            password,
          }),
        }
      ).then(({ json }) => ({ data: json }));
    },
    getUserRole: (userId: string): Promise<{ data: string }> => {
      return httpClient(`${process.env.REACT_APP_API_URL}/admin/${userId}/get-role`, {
        method: 'GET',
      }).then(({ json }) => ({ data: json }));
    },
  },
  CustomLifecycleCallbacks
);

export default dataProvider;
