import React, { useEffect } from 'react';
import { styled, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface HtmlPreviewDialogProps {
  visible: boolean;
  htmlText: string;
  onClose?: () => void;
}

export const HtmlPreviewDialog = (props: HtmlPreviewDialogProps) => {
  const handleClose = () => {
    !!props.onClose && props.onClose();
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      const iframe: HTMLIFrameElement = document.getElementById(
        'bodyHtmlPreviewIFrame'
      ) as HTMLIFrameElement;
      if (iframe) {
        const iframeDoc = iframe?.contentDocument || iframe.contentWindow?.document;
        if (iframeDoc) {
          iframeDoc.body.innerHTML = props.htmlText || '';
        }
      }
    });
  }, [props.visible, props.htmlText]);

  return (
    <StyledDialog
      fullWidth={true}
      open={props.visible}
      aria-labelledby="customized-dialog-title"
      onClose={handleClose}
    >
      <DialogTitle>Html Body Preview</DialogTitle>
      <DialogContent dividers>
        <iframe
          id="bodyHtmlPreviewIFrame"
          title="Email HTML Body Preview"
          style={{ width: '99%' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </StyledDialog>
  );
};
