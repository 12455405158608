import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BulkDeleteWithConfirmButton,
  SelectField,
} from 'react-admin';
import { TestTypeChoices } from 'types';

export const ResultPDFConfigList = (props: any) => {
  const BulkActionButtons = () => {
    return (
      <Fragment>
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmContent="Are you sure you want to delete the selected Result PDF Configuration entries?"
        />
      </Fragment>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={<BulkActionButtons />}
      title="PDF Configuration for Mobile Results Sharing"
    >
      <Datagrid>
        <SelectField source="TestType" label="Test Type" choices={TestTypeChoices} />
        <TextField source="Title" label="PDF Title" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
