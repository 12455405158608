import React from 'react';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  DateTimeInput,
  maxLength,
  BooleanInput,
} from 'react-admin';
import { SystemMessageTypeChoices, SystemMessageLevelChoices } from '../../types';

export const SystemMessageCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <DateTimeInput source="StartAt" label="Start At" validate={required()} />
      <DateTimeInput source="EndAt" label="End At" />
      <BooleanInput source="IsInactive" label="Is Inactive" />
      <SelectInput
        source="Type"
        label="Message Type"
        choices={SystemMessageTypeChoices}
        validate={required()}
      />
      <SelectInput
        source="Level"
        label="Message Level"
        choices={SystemMessageLevelChoices}
        validate={required()}
      />
      <TextInput
        source="Message"
        label="Message Text"
        multiline={true}
        fullWidth
        validate={[required(), maxLength(2000, 'System Messages are limited to 2000 characters')]}
      />
    </SimpleForm>
  </Create>
);
