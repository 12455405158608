export const GENDER_CODES: { [key: string]: string } = {
  F: 'Female',
  M: 'Male',
  O: 'Other',
  U: 'Prefer not to answer',
};

export const RACE_CODES: { [key: string]: string } = {
  '1002-5': 'American Indian or Alaska Native',
  '2028-9': 'Asian',
  '2054-5': 'Black or African American',
  '2076-8': 'Native Hawaiian or Other Pacific Islander',
  '2106-3': 'White',
  '2131-1': 'Other Race',
};

export const ETHNICITY_CODES: { [key: string]: string } = {
  H: 'Hispanic or Latino',
  N: 'Not Hispanic or Latino',
  U: 'Unknown',
};
