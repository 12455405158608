import { required, maxLength, regex } from 'react-admin';

export const hexColorRegExp = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');

export const validateTitle = [
  required('* Required'),
  maxLength(80, '* Must be less than 80 characters'),
];
export const validateKitName = [
  required('* Required'),
  maxLength(80, '* Must be less than 80 characters'),
];
export const validateKitType = [
  required('* Required'),
  maxLength(40, '* Must be less than 40 characters'),
];
export const validateHexColor = [regex(hexColorRegExp, '* Must be a valid Hex Color code')];

export const validateUrl = [
  regex(
    new RegExp(
      '^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$'
    ),
    '* Must be a valid url, starting with http(s)'
  ),
];
