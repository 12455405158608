import { FunctionField } from 'react-admin';

export const ResearchTestTypeChoices: { id: string; name: string }[] = [
  { id: 'qcovidantigen', name: 'COVID' },
  { id: 'qcovidflu', name: 'COVIDFLU' },
  { id: 'qflu', name: 'FLU' },
  { id: 'qrsv', name: 'RSV' },
];

export const ResearchTestTypeField = (props: any) => {
  return (
    <FunctionField
      render={(record: any) => {
        // traverse the source in to the object
        const parts = (props.source || '').split('.');
        const value: any = parts.reduce((obj: any, key: string) => {
          if (typeof obj === 'object' && obj !== null) {
            return obj[key];
          }

          return undefined;
        }, record);

        const testType = (value || '').toLowerCase();

        return ResearchTestTypeChoices.find((item) => item.id === testType)?.name || 'UNKNOWN';
      }}
      {...props}
    />
  );
};
