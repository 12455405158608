import { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import {
  Admin,
  Resource,
  defaultTheme,
  Layout,
  Menu,
  CustomRoutes,
  usePermissions,
  useLogout,
} from 'react-admin';
import { Route } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NoteIcon from '@mui/icons-material/Note';
import PeopleIcon from '@mui/icons-material/People';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import SettingsIcon from '@mui/icons-material/Settings';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MessageIcon from '@mui/icons-material/Message';
import Movie from '@mui/icons-material/Movie';
import VideoLibrary from '@mui/icons-material/VideoLibrary';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import ListIcon from '@mui/icons-material/List';

import config from './config';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import { USER_TYPES } from './utils/constants';

import TestResultList from './components/TestResultList';
import TestResult from 'components/TestResult/TestResult';
import { AdminUserList, AdminUserInvite, AdminUserEdit } from './components/AdminUser';
import UsersList from './components/UsersList';
import User from './components/UsersList/User';
import { Login } from './components/Login';
import { DocumentCreate, DocumentEdit, DocumentList } from './components/DocumentList';
import {
  MobileVersionCreate,
  MobileVersionEdit,
  MobileVersionList,
} from 'components/MobileVersion';
import {
  ResultPDFConfigCreate,
  ResultPDFConfigEdit,
  ResultPDFConfigList,
} from 'components/ResultPDFConfig';
import {
  SystemMessageList,
  SystemMessageCreate,
  SystemMessageEdit,
} from 'components/SystemMessage';
import { TestAdVideoList, TestAdVideoCreate, TestAdVideoEdit } from './components/TestAdVideo';
import {
  TestAdVideoPlaylistList,
  TestAdVideoPlaylistCreate,
  TestAdVideoPlaylistEdit,
} from './components/TestAdVideoPlaylist';
import { SystemSettings } from './components/SystemSettings';
import { EmailMessageCreate, EmailMessageEdit, EmailMessageList } from 'components/EmailMessage';
import {
  ResearchAccessCreate,
  ResearchAccessEdit,
  ResearchAccessList,
} from './components/ResearchAccess';
import { ResearchResultList, ResearchResultShow } from './components/ResearchResult';

const DEFAULT_TIMEOUT_SECS = 10 * 60; // 10 minute idle timeout
const DEFAULT_PROMPT_BEFORE_SECS = 1 * 60; // 1 minute prompt before idle timeout

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

const theme = {
  ...defaultTheme,
};

const AppMenu = () => {
  const { permissions } = usePermissions();
  return (
    <Menu>
      <Menu.ResourceItem name="results" />
      <Menu.ResourceItem name="users" />
      <Menu.ResourceItem name="admins" />
      <Menu.ResourceItem name="mobile-version" />
      <Menu.ResourceItem name="documents" />
      <Menu.ResourceItem name="result-pdf-config" />
      <Menu.ResourceItem name="test-ad-video" />
      <Menu.ResourceItem name="test-ad-video-playlist" />
      <Menu.ResourceItem name="email-message" />
      <Menu.ResourceItem name="system-message" />
      {permissions === USER_TYPES.ADMIN && <Divider />}
      <Menu.ResourceItem name="research-access" />
      <Menu.ResourceItem name="research-result" />
      {permissions === USER_TYPES.ADMIN && <Divider />}
      {permissions === USER_TYPES.ADMIN && (
        <Menu.Item primaryText="System Settings" to="/settings" leftIcon={<SettingsIcon />} />
      )}
    </Menu>
  );
};

const AppLayout = (props: any) => {
  const logout = useLogout();
  const [remaining, setRemaining] = useState<number>(0);
  const [showAutologoutWarning, setShowAutologoutWarning] = useState<boolean>(false);

  const onIdle = () => {
    // idle time has expired, perform logout
    logout();
  };

  const onPrompt = () => {
    // prompt before logout triggered, show the warning popup
    setShowAutologoutWarning(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    timeout: DEFAULT_TIMEOUT_SECS * 1000,
    promptBeforeIdle: DEFAULT_PROMPT_BEFORE_SECS * 1000,
    onIdle,
    onPrompt,
  });

  const handleDialogClose = () => {
    setShowAutologoutWarning(false);
    // reactivate (reset) the idle detection
    activate();
  };

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(intervalTimer);
    };
  }, [getRemainingTime]);

  return (
    <>
      <Layout {...props} menu={AppMenu} />

      {/* SESSION IDLE AUTOLOGOUT WARNING DIALOG */}
      <Dialog open={showAutologoutWarning} onClose={handleDialogClose}>
        <DialogTitle>Are you still there?</DialogTitle>
        <DialogContent>
          <DialogContentText>Logging you out in {remaining} seconds.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>I'm Here</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function App() {
  return (
    <Admin
      disableTelemetry
      theme={theme}
      loginPage={Login}
      authProvider={authProvider}
      dataProvider={dataProvider}
      layout={AppLayout}
      requireAuth
    >
      {(permissions) => {
        const menu = [];
        if (permissions === USER_TYPES.ADMIN || permissions === USER_TYPES.SUPPORT) {
          menu.push(
            <Resource
              name="results"
              options={{ label: 'Test Results' }}
              list={TestResultList}
              icon={AssignmentIcon}
              show={TestResult}
            />,
            <Resource
              name="users"
              options={{ label: 'Users' }}
              list={UsersList}
              icon={PeopleIcon}
              show={User}
            />
          );
        }

        // Only include the categories resource for admin users
        if (permissions === USER_TYPES.ADMIN) {
          menu.push(
            <Resource
              name="admins"
              options={{ label: 'Administrators' }}
              create={AdminUserInvite}
              edit={AdminUserEdit}
              list={AdminUserList}
              icon={SupervisorAccountIcon}
            />,
            <Resource
              name="mobile-version"
              options={{
                label: 'Mobile Versions',
              }}
              icon={MobileFriendlyIcon}
              list={MobileVersionList}
              edit={MobileVersionEdit}
              create={MobileVersionCreate}
            />,
            <Resource
              name="documents"
              icon={NoteIcon}
              list={DocumentList}
              edit={DocumentEdit}
              create={DocumentCreate}
            />,
            <Resource
              name="result-pdf-config"
              options={{
                label: 'PDF Configuration',
              }}
              icon={PictureAsPdfIcon}
              list={ResultPDFConfigList}
              edit={ResultPDFConfigEdit}
              create={ResultPDFConfigCreate}
            />,
            <Resource
              name="test-ad-video"
              options={{
                label: 'Test Ad Videos',
              }}
              icon={Movie}
              list={TestAdVideoList}
              create={TestAdVideoCreate}
              edit={TestAdVideoEdit}
            />,
            <Resource
              name="test-ad-video-playlist"
              options={{
                label: 'Test Ad Video Playlist',
              }}
              icon={VideoLibrary}
              list={TestAdVideoPlaylistList}
              create={TestAdVideoPlaylistCreate}
              edit={TestAdVideoPlaylistEdit}
            />,
            <Resource
              name="email-message"
              options={{
                label: 'Email Configurations',
              }}
              icon={EmailIcon}
              list={EmailMessageList}
              edit={EmailMessageEdit}
              create={EmailMessageCreate}
            />,
            <Resource
              name="system-message"
              options={{
                label: 'System Messages',
              }}
              icon={MessageIcon}
              list={SystemMessageList}
              edit={SystemMessageEdit}
              create={SystemMessageCreate}
            />,
            <CustomRoutes>
              <Route path="/settings" element={<SystemSettings />} />
            </CustomRoutes>,
            <Resource
              name="research-access"
              options={{
                label: 'Research Access Keys',
              }}
              icon={KeyIcon}
              list={ResearchAccessList}
              edit={ResearchAccessEdit}
              create={ResearchAccessCreate}
            />
          );
        }

        if (permissions === USER_TYPES.ADMIN || permissions === USER_TYPES.RESEARCH) {
          console.log('INCLUDE RESEARCH RESULTS');
          menu.push(
            <Resource
              name="research-result"
              options={{
                label: 'Research Results',
              }}
              icon={ListIcon}
              list={ResearchResultList}
              show={ResearchResultShow}
            />
          );
        }

        return menu;
      }}
    </Admin>
  );
}

export default App;
