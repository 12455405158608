import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  DateField,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  SimpleShowLayout,
} from 'react-admin';
import { TestTypeChoices, MenuActionTypeChoices } from '../../types';

export const MobileVersionEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SimpleShowLayout>
          <TextField source="ID" label="ID" />
          <DateField source="CreatedAt" label="Created At" showTime={true} />
          <DateField source="UpdatedAt" label="Updated At" showTime={true} />
        </SimpleShowLayout>
        <TextInput
          source="AppBundleID"
          label="App ID / Bundle ID"
          required={true}
          fullWidth={true}
        />
        <TextInput source="Version" label={'Minimum App Version'} required={true} type="number" />
        <TextInput
          source="TestAuthorizationUrl"
          label={'Test Authorization Url (TBD3.1)'}
          required={true}
          type="url"
          fullWidth={true}
        />
        <CheckboxGroupInput
          source="AllowedTestTypes"
          label="Allowed Test Types"
          // required={true}
          choices={TestTypeChoices}
        />
        <TextInput source="AppStoreUrl" label={'Apple App Store Url'} type="url" fullWidth={true} />
        <TextInput
          label={'Google Play Store Url'}
          source="PlayStoreUrl"
          type="url"
          fullWidth={true}
        />
        <TextInput
          label={'Quidel Barocde API Hostname'}
          source="BarcodeApiHostname"
          fullWidth={true}
        />
        <TextInput
          label={'Quidel Barocde APIKEY'}
          source="BarcodeApiKey"
          style={{ width: '50%' }}
        />
        <hr />
        <ArrayInput source="AppSettings" label="App Configuration Settings">
          <SimpleFormIterator inline>
            <TextInput source="name" required={true} sx={{ minWidth: '20em' }} />
            <TextInput source="value" sx={{ minWidth: '20em' }} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="AuthorizationUrls" fullWidth>
          <SimpleFormIterator disableReordering={true} fullWidth>
            <SelectInput
              source="TestType"
              label="Test Type"
              required={true}
              choices={TestTypeChoices}
            />
            <TextInput
              source="DocumentUrl"
              label="Test Authroization Url"
              required={true}
              fullWidth={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="SupportMenuItems" fullWidth>
          <SimpleFormIterator fullWidth>
            <SelectInput
              source="ActionType"
              label="Menu Action"
              required={true}
              choices={MenuActionTypeChoices}
            />
            <TextInput source="IconName" label="Menu Icon" />
            <TextInput
              source="MenuLabel"
              label="Menu Label"
              required={true}
              style={{ width: '50%' }}
            />
            <TextInput
              source="MenuValue"
              label="Menu Value"
              required={true}
              style={{ width: '50%' }}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
