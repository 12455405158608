import React from 'react';
import {
  Edit,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  DateTimeInput,
  TextField,
  DateField,
  SimpleShowLayout,
  BooleanInput,
} from 'react-admin';
import { SystemMessageTypeChoices, SystemMessageLevelChoices } from '../../types';

export const SystemMessageEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <DateField source="CreatedAt" label="Created At" showTime={true} />
          <DateField source="UpdatedAt" label="Updated At" showTime={true} />
        </SimpleShowLayout>
        <DateTimeInput source="StartAt" label="Start At" validate={required()} />
        <DateTimeInput source="EndAt" label="End At" />
        <BooleanInput source="IsInactive" label="Is Inactive" />
        <SelectInput
          source="Type"
          label="Message Type"
          choices={SystemMessageTypeChoices}
          validate={required()}
        />
        <SelectInput
          source="Level"
          label="Message Level"
          choices={SystemMessageLevelChoices}
          validate={required()}
        />
        <TextInput source="Message" label="Message Text" multiline={true} />
      </SimpleForm>
    </Edit>
  );
};
