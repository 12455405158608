import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  TextInput,
  DateInput,
  SelectInput,
  SelectField,
  NumberField,
} from 'react-admin';
import { CompletedAtField } from '../Common';
import { ResearchCaptureModeChoices } from 'types';
import { ResearchTestTypeChoices, ResearchTestTypeField } from './utils';

const postFilters = [
  <TextInput label="Test ID" source="SupportCode" defaultValue="" alwaysOn />,
  <TextInput label="Session Code" source="SessionCode" defaultValue="" />,
  <SelectInput
    label="Test Type"
    source="TestType"
    choices={ResearchTestTypeChoices}
    defaultValue={ResearchTestTypeChoices[0].id}
  />,
  <TextInput label="Device ID" source="DeviceID" defaultValue="" />,
  <SelectInput
    label="Capture Mode"
    source="CaptureMode"
    choices={ResearchCaptureModeChoices}
    defaultValue="AutoCapture"
  />,
  <DateInput label="Captured On" source="CreatedAt" defaultValue="" />,
];

export const ResearchResultList = (props: any) => {
  return (
    <List {...props} filters={postFilters} sort={{ field: 'CreatedAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="Test ID" source="TestResult.SupportCode" />
        <ResearchTestTypeField label="Test Type" source="TestResult.TestType" />
        <TextField label="Session Code" source="SessionCode" emptyText="N/A" />
        <NumberField label="Sequence Index" source="SequenceIndex" />
        <SelectField
          label="Capture Mode"
          source="SessionJson.captureMode"
          choices={ResearchCaptureModeChoices}
          emptyText="* UNDEFINED *"
        />
        <CompletedAtField label="Captured At" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
