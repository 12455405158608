import { useEffect, useState } from 'react';
import { useRecordContext, Button } from 'react-admin';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const imageClasses = {
  magnifyWrapper: `ImageComponent-magnifyWrapper`,
  progressWrapper: `ImageComponent-progressWrapper`,
  imageWrapper: `ImageComponent-imageWrapper`,
};

const ImageRoot = styled('div')(({ theme: Theme }) => ({
  [`& .${imageClasses.magnifyWrapper}`]: {
    background: 'gray',
  },

  [`&.${imageClasses.progressWrapper}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
  },

  [`& .${imageClasses.imageWrapper}`]: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    minHeight: 600,
    overflow: 'hidden',
  },
}));

export interface ImageComponentProps {
  source?: string;
}

export const ImageComponent = ({ source }: ImageComponentProps) => {
  const recordContext = useRecordContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [isInvalidImage, setIsInvalidImage] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');

  useEffect(() => {
    setLoading(true);

    // traverse the source in to the object
    const parts = (source || '').split('.');
    const sourceName = parts[parts.length - 1];
    const record: any = parts.reduce((obj: any, key: string, idx: number, arr: string[]) => {
      if (idx === arr.length - 1) {
        return obj;
      } else if (typeof obj === 'object' && obj !== null) {
        return obj[key];
      }

      return undefined;
    }, recordContext || {});

    const imageUrl = record[sourceName];

    console.log('>>>> ', imageUrl);

    if (imageUrl !== undefined) {
      setImageUrl(imageUrl || '');
      setFileName(record['FileName'] || '');
      if (imageUrl && imageUrl.length > 0) {
        fetch(imageUrl)
          .then((response) => {
            setIsInvalidImage(response.status !== 200);
          })
          .catch(() => {
            setIsInvalidImage(true);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setIsInvalidImage(true);
        setLoading(false);
      }
    }
  }, [recordContext, source]);

  const downloadImage = async () => {
    const image = await fetch(imageUrl);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = fileName.split('/')[1];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <ImageRoot className={imageClasses.progressWrapper}>
        <CircularProgress size={20} />
      </ImageRoot>
    );
  }

  return isInvalidImage ? (
    <p>No Image</p>
  ) : (
    <ImageRoot>
      <div>
        <div className={imageClasses.imageWrapper}>
          <ReactPanZoom image={imageUrl} alt="Test Result Image" />
        </div>
        <div>
          <Button onClick={downloadImage} label="Download Image" />
        </div>
      </div>
    </ImageRoot>
  );
};
