import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BulkDeleteWithConfirmButton,
  SelectField,
  FunctionField,
} from 'react-admin';
import { TestTypeChoices, EmailMessageTypeChoices, TestResultValueChoices } from 'types';

export const EmailMessageList = (props: any) => {
  const BulkActionButtons = () => {
    return (
      <Fragment>
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmContent="Are you sure you want to delete the selected Result PDF Configuration entries?"
        />
      </Fragment>
    );
  };

  return (
    <List {...props} bulkActionButtons={<BulkActionButtons />} title="Email Message Configurations">
      <Datagrid>
        <SelectField source="TestType" label="Test Type" choices={TestTypeChoices} />
        <SelectField
          source="MessageType"
          label="Email Message Type"
          choices={EmailMessageTypeChoices}
        />
        <SelectField
          source="TestResultValue"
          label="Test Result Value"
          emptyText="N/A"
          choices={TestResultValueChoices}
        />
        <TextField source="Subject" label="Email Subject" />
        <FunctionField
          source="BodyHtml"
          label="Html Body?"
          render={(record: any) => (record.BodyHtml ? 'YES' : 'NO')}
        />
        <FunctionField
          source="BodyText"
          label="Text Body?"
          render={(record: any) => (record.BodyText ? 'YES' : 'NO')}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
