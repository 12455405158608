import PropTypes from 'prop-types';
import { Form, TextInput, PasswordInput, useTranslate } from 'react-admin';
import { styled } from '@mui/material/styles';
import { Button, CardActions, CircularProgress } from '@mui/material';

const PREFIX = 'RaLoginForm';

const classes = {
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  container: `${PREFIX}-container`,
};

const Root = styled('div')(({ theme: Theme }) => ({
  [`& .${classes.form}`]: {},
  [`& .${classes.input}`]: {},
  [`& .${classes.button}`]: {
    width: '100%',
  },
  [`& .${classes.icon}`]: {
    marginRight: Theme.spacing(1),
  },
  [`& .${classes.container}`]: {
    marginTop: '1em',
  },
}));

interface Props {
  loading: boolean;
  onSubmit: (values: any) => void;
  onForgotPassword: () => void;
}

interface FormData {
  username: string;
  password: string;
}

const LoginForm = (props: Props) => {
  const { onSubmit, onForgotPassword, loading } = props;
  const translate = useTranslate();

  const validate = (values: FormData) => {
    const errors: {
      username?: string;
      password?: string;
    } = {};

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={(values) => onSubmit(values)}
      validate={(values) => validate(values as FormData)}
    >
      <Root>
        <div className={classes.form}>
          <div className={classes.input}>
            <TextInput
              autoFocus
              fullWidth
              id="username"
              source="username"
              label={translate('ra.auth.username')}
              disabled={loading}
            />
          </div>
          <div className={classes.input}>
            <PasswordInput
              fullWidth
              id="password"
              source="password"
              label={translate('ra.auth.password')}
              disabled={loading}
              autoComplete="current-password"
            />
          </div>
        </div>
        <CardActions>
          <div style={{ width: '100%' }}>
            <div>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.button}
              >
                {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                {translate('ra.auth.sign_in')}
              </Button>
            </div>
            <div className={classes.container} style={{ textAlign: 'right' }}>
              <Button
                variant="text"
                style={{ minWidth: '60%', fontSize: '0.8em' }}
                onClick={onForgotPassword}
              >
                Forgot Password
              </Button>
            </div>
          </div>
        </CardActions>
      </Root>
    </Form>
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default LoginForm;
