import { ResourceCallbacks } from 'react-admin';

export const CustomLifecycleCallbacks: ResourceCallbacks[] = [
  {
    resource: 'mobile-version',
    afterRead: async (record) => {
      // Create the "AppSettings" data for easier consumption by ArrayField, ArrayInput, etc. components
      record.AppSettings = Object.keys(record.AppSettingsJson || {}).map((key: string) => ({
        name: key,
        value: '' + record.AppSettingsJson[key],
      }));
      return record;
    },
    beforeSave: async (record) => {
      if ((record.AppSettings || []).length > 0) {
        // convert the "AppSettings" data into a JSON blob
        record.AppSettingsJson = record.AppSettings.reduce((json: any, entry: any) => {
          if (entry.name) {
            json[entry.name] = isNaN(entry.value) ? entry.value : +entry.value;
          }
          return json;
        }, {});
      } else {
        record.AppSettingsJson = null;
      }

      // remove the app settings from record before saving
      delete record.AppSettings;

      return record;
    },
  },
];
