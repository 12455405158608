import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
  FormDataConsumer,
} from 'react-admin';
import { color } from 'utils/color';
import { TestTypeChoices } from '../../types';
import {
  validateTitle,
  validateKitName,
  validateKitType,
  validateHexColor,
  hexColorRegExp,
} from './validation';

export const ResultPDFConfigEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput
          source="TestType"
          label="Test Type"
          required={true}
          choices={TestTypeChoices}
        />
        <TextInput source="Title" label={'PDF Title'} validate={validateTitle} fullWidth={true} />
        <TextInput
          source="TestKitName"
          label={'Test Kit Name'}
          validate={validateKitName}
          helperText="e.g. QuickVue® At-Home\nCOVID-19 Test ('\n' to include line break)"
          style={{ width: '50%' }}
        />
        <TextInput
          source="TestKitType"
          label={'Test Kit Type'}
          validate={validateKitType}
          helperText="e.g. Antigen"
          style={{ width: '50%' }}
        />
        <TextInput
          source="HeaderHexColor"
          label={'Header HexColor'}
          validate={validateHexColor}
          helperText="e.g. #774DEF"
          style={{ width: '50%' }}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let hexColor = color.DEFAULT_IMAGE_UPLOAD_BACKGROUND;
            if (
              !!formData.HeaderHexColor &&
              formData.HeaderHexColor.match(hexColorRegExp) != null
            ) {
              hexColor = formData.HeaderHexColor;
            }
            return (
              <ImageInput
                source="LogoImage"
                label="Logo Image File"
                sx={{ backgroundColor: hexColor }}
                {...rest}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            );
          }}
        </FormDataConsumer>
        <ImageInput
          source="FooterImage"
          label="Footer Image File"
          sx={{ backgroundColor: color.DEFAULT_IMAGE_UPLOAD_BACKGROUND }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput label={'Product Text'} source="ProductText" fullWidth={true} />
        <ArrayInput source="ProductInstructions" label="Product Instructions">
          <SimpleFormIterator>
            <TextInput
              key={Math.random()}
              label="Product Instructions"
              source=""
              fullWidth={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
