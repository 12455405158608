import { useRecordContext } from 'react-admin';
import { styled } from '@mui/material/styles';
import { color } from 'utils/color';

const resultClasses = {
  positive: `ResultField-positive`,
  negative: `ResultField-negative`,
  invalid: `ResultField-invalid`,
};

const ResultRoot = styled('span')(({ theme: Theme }) => ({
  [`& .${resultClasses.positive}`]: {
    color: color.RESULT_POSITIVE,
  },

  [`& .${resultClasses.negative}`]: {
    color: color.RESULT_NEGATIVE,
  },

  [`& .${resultClasses.invalid}`]: {
    color: color.RESULT_INVALID,
  },
}));

export function ResultField({ source }: any) {
  const record = useRecordContext() || {};

  let result = '';
  let styleClass = '';

  const value = record[source];

  switch (value) {
    case true:
      result = 'Positive';
      styleClass = resultClasses.positive;
      break;
    case false:
      result = 'Negative';
      styleClass = resultClasses.negative;
      break;
    default:
      result = 'Invalid';
      styleClass = resultClasses.invalid;
  }

  return record ? (
    <ResultRoot>
      <span className={styleClass}>{result}</span>
    </ResultRoot>
  ) : null;
}
