import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  PasswordInput,
  TextInput,
  CreateProps,
  email,
  SaveButton,
  Toolbar,
  useNotify,
  useRefresh,
  useRedirect,
  SelectInput,
} from 'react-admin';
import IconSend from '@mui/icons-material/Send';
import { AdminRoleChoices } from 'types';

export const AdminUserInvite = (props: CreateProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleSuccess = () => {
    notify('Invitation sent successfully.');
    redirect('/admins');
    refresh();
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess: handleSuccess }} title="Invite Admin User">
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton label="Send Invite" icon={<IconSend />} />
          </Toolbar>
        }
      >
        <TextInput autoFocus source="name" validate={required('Name field is required.')} />
        <TextInput
          source="email"
          validate={[required('Email field is required.'), email('Email not valid.')]}
        />
        <PasswordInput source="password" validate={required('Password field is required.')} />
        <SelectInput
          source="role"
          choices={AdminRoleChoices}
          validate={required('User role is required.')}
        />
      </SimpleForm>
    </Create>
  );
};
