import { AuthProvider } from 'react-admin';
import { buildAuthProvider } from 'react-admin-amplify';
import config from '../config';
import dataProvider from 'providers/dataProvider';

const _authProvider = buildAuthProvider();

const authProvider: AuthProvider = {
  ..._authProvider,
  logout: async (params: any) => {
    // remove cognito values in local storage
    for (const key in localStorage) {
      if (key.startsWith('CognitoIdentityServiceProvider.')) {
        localStorage.removeItem(key);
      }
    }

    return _authProvider.logout(params);
  },
  getPermissions: async () => {
    const lastLoggedUser = localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.LastAuthUser`
    );
    if (!lastLoggedUser) {
      return Promise.reject();
    }
    const { data } = await dataProvider.getUserRole(lastLoggedUser);
    return data ? Promise.resolve(data) : Promise.reject();
  },
  checkError: async (error: any) => {
    if (error.status === 401 || error.status === 403) {
      window.location.reload();
    }
  },
};

export default authProvider;
