import PropTypes from 'prop-types';
import { Form, TextInput, PasswordInput, useTranslate } from 'react-admin';
import { styled } from '@mui/material/styles';
import { Button, CardActions, CircularProgress } from '@mui/material';

const PREFIX = 'RaLoginForm';

const classes = {
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme: Theme }) => ({
  [`& .${classes.form}`]: {
    padding: '0 1em 1em 1em',
  },

  [`& .${classes.input}`]: {
    marginTop: '1em',
  },

  [`& .${classes.button}`]: {
    minWidth: '40%',
  },

  [`& .${classes.icon}`]: {
    marginRight: Theme.spacing(1),
  },
}));

interface Props {
  loading: boolean;
  onSubmit: (values: ConfirmPasswordFormData) => void;
  onCancel: () => void;
}

export interface ConfirmPasswordFormData {
  code: string;
  password: string;
  confirmPassword: string;
}

const ConfirmPasswordForm = (props: Props) => {
  const { onSubmit, onCancel, loading } = props;
  const translate = useTranslate();

  const validate = (values: ConfirmPasswordFormData) => {
    let errors: {
      code?: string;
      password?: string;
      confirmPassword?: string;
    } = {};
    if (!values.code) {
      errors.code = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = translate('ra.validation.required');
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };

  return (
    <Form
      onSubmit={(values) => onSubmit(values as ConfirmPasswordFormData)}
      validate={(values) => validate(values as ConfirmPasswordFormData)}
    >
      <Root>
        <div className={classes.form}>
          <p>Enter Validation Code and new Password</p>
          <div className={classes.input}>
            <TextInput
              fullWidth
              id="code"
              source="code"
              label="Verification Code"
              disabled={loading}
              autoComplete="one-time-code"
            />
          </div>
          <div className={classes.input}>
            <PasswordInput
              fullWidth
              id="password"
              source="password"
              label={translate('ra.auth.password')}
              disabled={loading}
            />
          </div>
          <div className={classes.input}>
            <PasswordInput
              fullWidth
              id="confirmPassword"
              source="confirmPassword"
              label="Confirm Password"
              disabled={loading}
            />
          </div>
        </div>
        <CardActions>
          <div
            style={{
              paddingTop: '1em',
              paddingBottom: '0.5em',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="text"
              color="primary"
              disabled={loading}
              className={classes.button}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              Submit
            </Button>
          </div>
        </CardActions>
      </Root>
    </Form>
  );
};

ConfirmPasswordForm.propTypes = {
  redirectTo: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ConfirmPasswordForm;
