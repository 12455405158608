import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
  TextInput,
  useRecordContext,
  DateInput,
  FunctionField,
  usePermissions,
} from 'react-admin';
import { USER_TYPES, ResultValue } from 'utils/constants';
import { CompletedAtField, TestTypeField } from '../Common';
import { color } from 'utils/color';

const postFilters = [
  <TextInput label="Test ID" source="SupportCode" defaultValue="" alwaysOn />,
  <TextInput label="Test Type" source="TestType" defaultValue="" />,
  <TextInput label="Profile Name" source="UserName" defaultValue="" />,
  <TextInput label="Login Email" source="UserProfile.User.Email" defaultValue="" />,
  <DateInput label="Profile DOB" source="UserProfile.Dob" defaultValue="" />,
  <DateInput label="CreatedAt" source="CreatedAt" defaultValue="" />,
];

const TestResultField = ({ source }: any) => {
  const record = useRecordContext();

  const result = record.UserResult?.result || [];

  type DisplayResult = { value: ResultValue; color: string };

  let finalResult: DisplayResult = result.reduce(
    (result: DisplayResult, entry: { name: string; value: boolean | undefined }) => {
      if (entry.value === true || result.value === 'Positive') {
        return { value: 'Positive', color: color.RESULT_POSITIVE };
      } else if (entry.value === false) {
        return { value: 'Negative', color: color.RESULT_NEGATIVE };
      } else if (entry.value == null) {
        return { value: 'Invalid', color: color.RESULT_INVALID };
      }

      return result;
    },
    { value: 'Expired', color: color.RESULT_EXPIRED }
  );

  return <span style={{ color: finalResult.color }}>{finalResult.value}</span>;
};

const TestResultList = (props: any) => {
  const { permissions } = usePermissions();
  return (
    <List {...props} filters={postFilters} sort={{ field: 'CreatedAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="Test ID" source="SupportCode" />
        <TestTypeField label="Test Type" source="TestType" />
        <TextField label="Image Upload" source="ImageUploadStatus" emptyText="none" />
        {permissions === USER_TYPES.ADMIN && (
          <TestResultField label="Test Result" source="Result" />
        )}
        {permissions === USER_TYPES.ADMIN && <TextField source="State" />}
        <FunctionField
          label="Profile Name"
          render={(record: any) =>
            record?.UserProfile
              ? `${record?.UserProfile?.FirstName} ${record?.UserProfile?.LastName}`
              : null
          }
        />
        <TextField source="UserProfile.User.Email" label="Login Email" />
        <DateField source="UserProfile.Dob" label="Profile DOB" />
        <CompletedAtField label="Completed At" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default TestResultList;
