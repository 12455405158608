import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  UrlField,
  EditButton,
  BulkDeleteWithConfirmButton,
  SelectField,
} from 'react-admin';
import { DocumentLanguageChoices, DocumentTypeChoices } from '../../types';

export const DocumentList = (props: any) => {
  const BulkActionButtons = () => {
    return (
      <Fragment>
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmContent="Are you sure you want to delete the selected Documents?"
        />
      </Fragment>
    );
  };

  return (
    <List {...props} title="Legal Agreement Documents">
      <Datagrid bulkActionButtons={<BulkActionButtons />}>
        <DateField source="UpdatedAt" label="Updated At" showTime={true} />
        <TextField source="AppType" label="App ID / Bundle ID" />
        <SelectField source="DocumentType" label="Document Type" choices={DocumentTypeChoices} />
        <UrlField source="Url" label="Document Url" />
        <NumberField source="Version" label="Document Version" />
        <SelectField
          source="LanguageCode"
          label="Language Code"
          choices={DocumentLanguageChoices}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
