import { useRecordContext } from 'react-admin';

export function ResultTypeField({ source }: any) {
  const record = useRecordContext() || {};

  let result = '';

  const name = record[source] || '';

  switch (name) {
    case 'covid':
      result = 'COVID-19';
      break;
    default:
      result = name;
  }

  return record ? <span>{result}</span> : null;
}
