import { USER_TYPES, ResultValues } from 'utils/constants';

export type StateType = {
  isAuthenticated: boolean;
};

export const TestTypeChoices: { id: string; name: string }[] = [
  { id: 'QCOVIDANTIGEN', name: 'QCOVIDANTIGEN' },
  { id: 'QCOVIDREADONLY', name: 'QCOVIDREADONLY' },
  { id: 'QCOVIDFLU', name: 'QCOVIDFLU' },
  { id: 'QFLU', name: 'QFLU' },
  { id: 'QRSV', name: 'QRSV' },
];

export const EmailMessageTypeChoices: { id: string; name: string }[] = [
  { id: 'follow-up', name: 'Follow Up Email' },
];

export const MenuActionTypeChoices: { id: string; name: string }[] = [
  { id: 'phone', name: 'Call Phone Number' },
  { id: 'url', name: 'Open Url' },
];

export const DisplayTestTypeChoices: { id: string; name: string }[] = TestTypeChoices.concat([
  { id: 'BARCODETEST', name: 'BARCODETEST' },
]);

export const DocumentLanguageChoices: { id: string; name: string }[] = [
  { id: 'en', name: 'EN (English)' },
  { id: 'es', name: 'ES (Spanish)' },
  { id: 'fr', name: 'FR (French)' },
];

export const DocumentTypeChoices: { id: string; name: string }[] = [
  { id: 'PrivacyPolicy', name: 'Privacy Policy' },
  { id: 'PrivacyNotice', name: 'Privacy Notice' },
  { id: 'ProductConsent', name: 'Product Consent' },
  { id: 'TermsofService', name: 'Terms of Service' },
];

export const SystemMessageTypeChoices: { id: string; name: string }[] = [
  { id: 'all', name: 'System Wide' },
  { id: 'app', name: 'Mobile App Only' },
  { id: 'admin', name: 'Admin UI Only' },
];

export const SystemMessageLevelChoices: { id: string; name: string }[] = [
  { id: 'info', name: 'Information' },
  { id: 'warning', name: 'Warning' },
  { id: 'error', name: 'Error' },
];

export const ResearchAccessTypeChoices: { id: string; name: string }[] = [
  { id: 'admin', name: 'Admin Portal' },
  { id: 'app', name: 'App' },
  { id: 'api', name: 'API' },
];

export const AdminRoleChoices: { id: string; name: string }[] = Object.keys(USER_TYPES).map(
  (key) => ({
    name: key,
    id: USER_TYPES[key],
  })
);

export const TestResultValueChoices: { id: string; name: string }[] = ResultValues.map((value) => ({
  name: value.toUpperCase(),
  id: value,
}));

export const ResearchCaptureModeChoices: { id: string; name: string }[] = [
  { id: 'qvue', name: 'QVue' },
  { id: 'manual', name: 'Manual' },
  { id: 'autocapture', name: 'AutoCapture' },
];
