import {
  Show,
  TextField,
  EmailField,
  DateField,
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  usePermissions,
  useRecordContext,
  DeleteWithConfirmButton,
  FunctionField,
} from 'react-admin';
import { Grid, Typography, Divider } from '@mui/material';
import { USER_TYPES } from 'utils/constants';
import { GENDER_CODES, RACE_CODES, ETHNICITY_CODES } from 'components/Common';

const User = (props: any) => {
  const { permissions } = usePermissions();

  const OptionalDeleteButton = () => {
    const record = useRecordContext();

    return !record.IsDeleted ? (
      <DeleteWithConfirmButton
        record={record}
        resource="user-profile"
        redirect={`/users/${record.userid}`}
        confirmTitle={`Delete User Profile (${record.FirstName} ${record.LastName})?`}
        confirmContent={
          <>
            <div>
              Are you sure you want to delete this User Profile?
              <p>
                <b style={{ color: 'red' }}>THIS ACTION CANNOT BE UNDONE!</b>
              </p>
            </div>
          </>
        }
      />
    ) : null;
  };

  return (
    <Show emptyWhileLoading={true} {...props}>
      <Grid container spacing={2} sx={{ margin: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">User Account Details</Typography>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField source="FirstName" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField source="LastName" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <DateField label="Date of birth" source="Dob" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <EmailField label="Login Email" source="UserEmail" />
          </SimpleShowLayout>
        </Grid>
        {permissions === USER_TYPES.ADMIN && (
          <>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <EmailField label="Profile Email" source="Email" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField source="PhoneNumber" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <FunctionField
                  label="Gender"
                  render={(record: any) =>
                    GENDER_CODES[record?.GenderCode] || record?.GenderCode || ''
                  }
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <FunctionField
                  label="Race"
                  render={(record: any) => RACE_CODES[record?.RaceCode] || record?.RaceCode || ''}
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <FunctionField
                  label="Ethnicity"
                  render={(record: any) =>
                    ETHNICITY_CODES[record?.EthnicityCode] || record?.EthnicityCode || ''
                  }
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField source="StreetAddress" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField source="City" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField source="State" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField source="Zipcode" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField source="County" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField source="Country" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <DateField source="CreatedAt" />
              </SimpleShowLayout>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography variant="h6">Dependent Profiles</Typography>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ overflow: 'scroll', display: 'flex', maxWidth: 'calc(100vw - 301px) !important' }}
        >
          <ArrayField source="subProfiles" label="Dependent Profiles">
            <Datagrid bulkActionButtons={false}>
              <FunctionField
                label="Name"
                render={(record: any) => `${record.FirstName} ${record.LastName}`}
              />
              <DateField label="Date of birth" source="Dob" />
              {permissions === USER_TYPES.ADMIN && <EmailField label="Email" source="Email" />}
              {permissions === USER_TYPES.ADMIN && <TextField source="PhoneNumber" />}
              {permissions === USER_TYPES.ADMIN && (
                <FunctionField
                  label="Gender"
                  render={(record: any) =>
                    GENDER_CODES[record?.GenderCode] || record?.GenderCode || ''
                  }
                />
              )}
              {permissions === USER_TYPES.ADMIN && (
                <FunctionField
                  label="Race"
                  render={(record: any) => RACE_CODES[record?.RaceCode] || record?.RaceCode || ''}
                />
              )}
              {permissions === USER_TYPES.ADMIN && (
                <FunctionField
                  label="Ethnicity"
                  render={(record: any) =>
                    ETHNICITY_CODES[record?.EthnicityCode] || record?.EthnicityCode || ''
                  }
                />
              )}
              {permissions === USER_TYPES.ADMIN && <TextField source="StreetAddress" />}
              {permissions === USER_TYPES.ADMIN && <TextField source="City" />}
              {permissions === USER_TYPES.ADMIN && <TextField source="State" />}
              {permissions === USER_TYPES.ADMIN && <TextField source="Zipcode" />}
              {permissions === USER_TYPES.ADMIN && <TextField source="County" />}
              {permissions === USER_TYPES.ADMIN && <DateField source="CreatedAt" />}
              <OptionalDeleteButton />
            </Datagrid>
          </ArrayField>
        </Grid>
      </Grid>
    </Show>
  );
};

export default User;
