import {
  Show,
  TextField,
  EmailField,
  DateField,
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  useRecordContext,
  usePermissions,
} from 'react-admin';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Divider } from '@mui/material';

import { ImageComponent, ResultField, ResultTypeField, TestTypeField } from '../Common';
import { USER_TYPES } from '../../utils/constants';

const classes = {
  uppercase: `TestResult-uppercase`,
};

const StyledShow = styled(Show)(({ theme: Theme }) => ({
  [`& .${classes.uppercase}`]: {
    textTransform: 'uppercase',
  },
}));

const Title = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) return null;
  return <span>Result #{record.SupportCode}</span>;
};

const UserReportedResults = () => {
  const record = useRecordContext();

  const userResult = record.UserResult || [];
  const isExpired = (userResult.result || []).length === 0;

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">User Reported Results</Typography>
        <Divider />
      </Grid>
      <Grid item xs={3}>
        {isExpired ? (
          <div style={{ padding: '1em', fontSize: '0.9em' }}>** Test Expired</div>
        ) : (
          <ArrayField source="UserResult.result" label="Test Results">
            <Datagrid bulkActionButtons={false}>
              <ResultTypeField className={classes.uppercase} label="Test Type" source="name" />
              <ResultField label="Result" source="value" />
            </Datagrid>
          </ArrayField>
        )}
      </Grid>
    </>
  );
};

const ImageAnalysisResults = () => {
  const record = useRecordContext();

  return (
    <>
      <Grid item xs={12} mt={2}>
        <Typography variant="h6">Image Analysis Results</Typography>
        <Divider />
      </Grid>
      {record.Result ? (
        <Grid item xs={3}>
          <ArrayField source="Result" label="Test Results">
            <Datagrid bulkActionButtons={false}>
              <ResultTypeField className={classes.uppercase} label="Test Type" source="name" />
              <ResultField label="Result" source="value" />
            </Datagrid>
          </ArrayField>
        </Grid>
      ) : (
        <div style={{ padding: '1em', fontSize: '0.8em' }}>
          * No Image Analysis Results Available *
        </div>
      )}
    </>
  );
};

const TestResult = (props: any) => {
  const { permissions } = usePermissions();

  return (
    <StyledShow {...props} title={<Title />}>
      <Grid container sx={{ padding: 2 }}>
        <UserReportedResults />
        {permissions === USER_TYPES.ADMIN && <ImageAnalysisResults />}
        <Grid item xs={12} mt={2}>
          <Typography variant="h6">Test Information</Typography>
          <Divider />
        </Grid>
        <Grid container sx={{ margin: 0 }}>
          <Grid item xs={4}>
            <ImageComponent source="ImageLocation" />
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <TextField label="Test ID" source="SupportCode" />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <TestTypeField label="Test Type" source="TestType" />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <DateField source="CreatedAt" />
                </SimpleShowLayout>
              </Grid>
              {permissions === USER_TYPES.ADMIN && (
                <>
                  <Grid item xs={4}>
                    <SimpleShowLayout>
                      <TextField source="State" />
                    </SimpleShowLayout>
                  </Grid>
                  <Grid item xs={4}>
                    <SimpleShowLayout>
                      <DateField source="ProcessedAt" />
                    </SimpleShowLayout>
                  </Grid>
                  <Grid item xs={4}>
                    <SimpleShowLayout>
                      <TextField source="ProcessingDuration" />
                    </SimpleShowLayout>
                  </Grid>
                </>
              )}

              <Grid item xs={4}>
                <SimpleShowLayout>
                  <TextField source="ImageUploadStatus" />
                </SimpleShowLayout>
              </Grid>
              {permissions === USER_TYPES.ADMIN && (
                <Grid item xs={4}>
                  <SimpleShowLayout>
                    <TextField source="ImageAnalysisRevision" />
                  </SimpleShowLayout>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography variant="h6">User Profile information</Typography>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="First Name" source="UserProfile.FirstName" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Last Name" source="UserProfile.LastName" />
          </SimpleShowLayout>
        </Grid>
        {permissions === USER_TYPES.ADMIN && (
          <>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField label="Phone Number" source="UserProfile.PhoneNumber" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <EmailField label="Profile Email" source="UserProfile.Email" />
              </SimpleShowLayout>
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <SimpleShowLayout>
            <EmailField label="Login Email" source="UserProfile.User.Email" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <DateField label="DOB" source="UserProfile.Dob" />
          </SimpleShowLayout>
        </Grid>
        {permissions === USER_TYPES.ADMIN && (
          <>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField label="Street Address" source="UserProfile.StreetAddress" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField label="City" source="UserProfile.City" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField label="State" source="UserProfile.State" />
              </SimpleShowLayout>
            </Grid>
            {/* <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField label="Country" source="UserProfile.Country" />
              </SimpleShowLayout>
            </Grid> */}
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField label="Ethnicity Code" source="UserProfile.EthnicityCode" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField label="Race Code" source="UserProfile.RaceCode" />
              </SimpleShowLayout>
            </Grid>
          </>
        )}

        <Grid item xs={12} mt={2}>
          <Typography variant="h6">Device Information</Typography>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Brand" source="DeviceInformation.brand" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Model" source="DeviceInformation.model" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="OS" source="DeviceInformation.os" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="OS Version" source="DeviceInformation.osVersion" />
          </SimpleShowLayout>
        </Grid>
        {permissions === USER_TYPES.ADMIN && (
          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField label="Device Id" source="DeviceInformation.deviceID" />
            </SimpleShowLayout>
          </Grid>
        )}
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField label="Application Version" source="DeviceInformation.applicationVersion" />
          </SimpleShowLayout>
        </Grid>
        {permissions === USER_TYPES.ADMIN && (
          <Grid item xs={3}>
            <SimpleShowLayout>
              <TextField label="Is a Tablet" source="DeviceInformation.isTablet" />
            </SimpleShowLayout>
          </Grid>
        )}
      </Grid>
    </StyledShow>
  );
};

export default TestResult;
