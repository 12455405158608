import {
  Create,
  SimpleForm,
  required,
  TextInput,
  maxLength,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';

export const TestAdVideoCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="Description"
        label="Description"
        fullWidth
        validate={[required(), maxLength(2000, 'Description is limited to 2000 characters')]}
      />
      <ArrayInput source="Keywords">
        <SimpleFormIterator>
          <TextInput key={Math.random()} label="Keywords" source="" fullWidth={true} />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        source="VideoUrl"
        label="Video Url"
        fullWidth
        validate={[required(), maxLength(2000, 'Urls are limited to 2000 characters')]}
      />
    </SimpleForm>
  </Create>
);
