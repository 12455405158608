import {
  Edit,
  SimpleForm,
  SimpleShowLayout,
  required,
  TextField,
  TextInput,
  maxLength,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';

export const TestAdVideoEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
      </SimpleShowLayout>

      <TextInput
        source="Description"
        label="Description"
        fullWidth
        validate={[required(), maxLength(2000, 'Description is limited to 2000 characters')]}
      />
      <ArrayInput source="Keywords">
        <SimpleFormIterator>
          <TextInput key={Math.random()} label="Keywords" source="" fullWidth={true} />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        source="VideoUrl"
        label="Video Url"
        fullWidth
        validate={[required(), maxLength(2000, 'Urls are limited to 2000 characters')]}
      />
    </SimpleForm>
  </Edit>
);
