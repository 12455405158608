import PropTypes from 'prop-types';
import { Form, TextInput, useTranslate } from 'react-admin';
import { styled } from '@mui/material/styles';
import { Button, CardActions, CircularProgress } from '@mui/material';

const PREFIX = 'RaLoginForm';

const classes = {
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme: Theme }) => ({
  [`& .${classes.form}`]: {
    padding: '0 1em 1em 1em',
  },

  [`& .${classes.input}`]: {
    marginTop: '1em',
  },

  [`& .${classes.button}`]: {
    minWidth: '40%',
  },

  [`& .${classes.icon}`]: {
    marginRight: Theme.spacing(1),
  },
}));

interface Props {
  loading: boolean;
  onSubmit: (values: ForgotPasswordFormData) => void;
  onCancel: () => void;
}

export interface ForgotPasswordFormData {
  username: string;
}

const ForgotPasswordForm = (props: Props) => {
  const { onSubmit, onCancel, loading } = props;
  const translate = useTranslate();

  const validate = (values: ForgotPasswordFormData) => {
    const errors: { username?: string } = !values.username
      ? { username: translate('ra.validation.required') }
      : {};
    return errors;
  };

  return (
    <Form
      onSubmit={(values) => onSubmit({ username: values.username || '' })}
      validate={(values) => validate({ username: values.username })}
    >
      <Root>
        <div className={classes.form}>
          <p>Enter the username for the account to reset.</p>
          <p>
            You will receive an email message containing a confirmation code to be used in the next
            step.
          </p>
          <p style={{ fontSize: '0.7em' }}>
            NOTE: Continuing with the password reset will invalidate the current password.
          </p>
          <div className={classes.input}>
            <TextInput
              autoFocus
              fullWidth
              id="username"
              source="username"
              label={translate('ra.auth.username')}
              disabled={loading}
            />
          </div>
        </div>
        <CardActions>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="text"
              color="primary"
              disabled={loading}
              className={classes.button}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              Reset Password
            </Button>
          </div>
        </CardActions>
      </Root>
    </Form>
  );
};
ForgotPasswordForm.propTypes = {
  redirectTo: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ForgotPasswordForm;
