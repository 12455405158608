import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Loading,
  useGetList,
  SimpleForm,
  NumberInput,
  Toolbar,
  SaveButton,
  useCreate,
  useUpdate,
  Title,
  useNotify,
  useRefresh,
} from 'react-admin';

const DEFAULT_EXPIRARION_DAYS: number = 30;

const DEFAULT_REPORTING_LIMIT: number = 500;

const SaveOnlyToolbar = () => {
  return (
    <Toolbar>
      <SaveButton></SaveButton>
    </Toolbar>
  );
};

export const SystemSettings = () => {
  const getList = useGetList('system-settings', {});
  const [record, setRecord] = useState();
  const [id, setId] = useState<string>();
  const [create, createStatus] = useCreate();
  const [update, updateStatus] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = (data: any) => {
    if (record) {
      update('system-settings', { id, data: { ...data, id } });
    } else {
      create('system-settings', { data });
    }
  };

  useEffect(() => {
    const settings = (getList?.data || [])[0];
    setRecord(settings);
    const id = settings?.id || '';
    setId(id);
  }, [getList?.data]);

  useEffect(() => {
    if (createStatus.error || updateStatus.error) {
      notify('Error saving System Settings', { type: 'error' });
      refresh();
    }
  }, [createStatus.error, updateStatus.error, notify, refresh]);

  if (getList?.isLoading) {
    return <Loading loadingPrimary="Loading System Status..." />;
  } else if (createStatus.isLoading || updateStatus.isLoading) {
    return (
      <Loading
        loadingPrimary="Saving System Status..."
        loadingSecondary="The page is updating, just a moment please"
      />
    );
  }

  return (
    <Card sx={{ marginTop: '1em' }}>
      <SimpleForm
        warnWhenUnsavedChanges={true}
        onSubmit={handleSubmit}
        record={record}
        toolbar={<SaveOnlyToolbar />}
      >
        <Title title="System Settings" />
        <NumberInput
          source="TestResultImageExpirationDays"
          defaultValue={DEFAULT_EXPIRARION_DAYS}
          label="Test Result Image Expiration Days"
          helperText="* Number of days to keep Test Result Images"
        />
        <NumberInput
          source="ReportingTransmissionLimit"
          defaultValue={DEFAULT_REPORTING_LIMIT}
          label="Test Reporting Transmission Limit"
          helperText="* Maximum number of records transmitted for an individual Reporting job"
        />
      </SimpleForm>
    </Card>
  );
};
