import { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  BulkDeleteWithConfirmButton,
  SelectField,
} from 'react-admin';
import { SystemMessageTypeChoices, SystemMessageLevelChoices } from '../../types';

export const SystemMessageList = (props: any) => {
  const BulkActionButtons = () => {
    return (
      <Fragment>
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmContent="Are you sure you want to delete the selected System Messages?"
        />
      </Fragment>
    );
  };

  return (
    <List {...props} title="System Messages">
      <Datagrid bulkActionButtons={<BulkActionButtons />}>
        <DateField source="StartAt" label="Start At" showTime={true} />
        <DateField source="EndAt" label="End At" showTime={true} emptyText="No End Date/Time" />
        <SelectField
          source="IsInactive"
          label="Status"
          choices={[
            { id: false, name: 'ACTIVE' },
            { id: true, name: 'INACTIVE' },
          ]}
        />
        <SelectField source="Type" label="Message Type" choices={SystemMessageTypeChoices} />
        <SelectField source="Level" label="Message Level" choices={SystemMessageLevelChoices} />
        <TextField source="Message" label="Message Text" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
