import React from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  Pagination,
  PaginationProps,
  EditButton,
  useRecordContext,
  DeleteWithConfirmButton,
} from 'react-admin';
import PasswordResetButton from './Partials/PasswordResetButton';

const PostPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[20, 40, 60, 80, 100]} {...props} />
);

const DeleteAccountButton = () => {
  const record = useRecordContext();
  return (
    <DeleteWithConfirmButton
      confirmTitle={`Delete Admin Account?`}
      confirmContent={
        <>
          <div>
            Are you sure you want to delete the Admin Account for:
            <p>
              <b>
                {record.Name} ({record.Email})
              </b>
            </p>
            <p>
              <b style={{ color: 'red' }}>THIS ACTION CANNOT BE UNDONE!</b>
            </p>
          </div>
        </>
      }
    />
  );
};

export const AdminUserList = (props: ListProps) => (
  <List {...props} pagination={<PostPagination />} perPage={20}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserID" label="ID" />
      <TextField source="Name" />
      <TextField source="Email" />
      <TextField source="UserRole" label="User Type" />
      <PasswordResetButton />
      <DeleteAccountButton />
      <EditButton />
    </Datagrid>
  </List>
);
