import {
  List,
  Datagrid,
  TextField,
  TextInput,
  FunctionField,
  DateField,
  EmailField,
  ShowButton,
  usePermissions,
  useRecordContext,
  DeleteWithConfirmButton,
} from 'react-admin';
import PasswordResetButton from './Partials/PasswordResetButton';
import { QuickFilter } from 'components/Common';
import { USER_TYPES } from '../../utils/constants';

const OptionalShowButton = () => {
  const record = useRecordContext();
  return !!record.hasUserProfile ? <ShowButton /> : null;
};

const DeleteUserAccountButton = () => {
  const record = useRecordContext();
  return (
    <DeleteWithConfirmButton
      confirmTitle={`Delete User Account?`}
      confirmContent={
        <>
          <div>
            Are you sure you want to delete the User Account for:
            <p>
              <b>{record.Email}</b>
            </p>
            <p>
              <b style={{ color: 'red' }}>THIS ACTION CANNOT BE UNDONE!</b>
            </p>
          </div>
        </>
      }
    />
  );
};

const UserList = (props: any) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions === USER_TYPES.ADMIN;

  const userFilters: React.ReactNode[] = [
    <TextInput label="Email Address" source="Email" defaultValue="" />,
  ];

  if (isAdmin) {
    userFilters.push(
      <QuickFilter source="IncludeAll" label="Include Disabled Users" defaultValue={true} />
    );
  }

  return (
    <List filters={userFilters} exporter={isAdmin} {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="User ID" source="UserID" />
        <FunctionField
          label="Profile Name"
          source="ProfileName"
          sortable={false}
          render={(record: any) =>
            !!record.hasUserProfile ? record.ProfileName : '* NO USER PROFILE *'
          }
        />
        <EmailField label="Login Email" source="Email" />
        <TextField source="EmailVerified" />
        <FunctionField
          source="Enabled"
          label="Account Status"
          render={(record: any) => (record.Enabled ? 'Active' : 'Disabled')}
        />
        <DateField source="CreatedAt" />
        <DateField source="UpdatedAt" />
        <PasswordResetButton />
        <DeleteUserAccountButton />
        <OptionalShowButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
