import { useEffect, useState } from 'react';
import { useInput, useRecordContext } from 'react-admin';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { customAlphabet } from 'nanoid';
import { nolookalikes } from 'nanoid-dictionary';

export const generateAPIKey = () => {
  return customAlphabet(nolookalikes)(32);
};

export const APIKeyInput = (props: any) => {
  const { onChange, onBlur, ...rest } = props;
  const record = useRecordContext();
  const [apiKey, setApiKey] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ defaultValue: apiKey, onChange, onBlur, ...props });

  const updateKey = () => {
    setShowConfirmation(true);
  };

  const onConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const onConfirmationConfirmed = () => {
    setShowConfirmation(false);
    const key = generateAPIKey();
    field.onChange(key);
    setApiKey(key);
  };

  useEffect(() => {
    let initialAPIKey = record?.APIKey;
    if (!initialAPIKey) {
      initialAPIKey = generateAPIKey();
      field.onChange(initialAPIKey);
    }
    setApiKey(initialAPIKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.APIKey]);

  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item>
          <TextField
            {...field}
            label={props.label}
            value={apiKey}
            error={(isTouched || isSubmitted) && invalid}
            helperText={(isTouched || isSubmitted) && invalid ? error : ''}
            required={isRequired}
            InputProps={{ readOnly: true }}
            style={{ minWidth: '28em' }}
            {...rest}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" startIcon={<SyncIcon />} onClick={updateKey}>
            Regenerate
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={showConfirmation}
        onClose={onConfirmationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Regenerate the Research Access API Key?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Regenerating the Research Access API key will create a new API Key and invalidate the
            existing API Key.
            <br />
            <br />
            Are you sure you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmationClose} autoFocus>
            No
          </Button>
          <Button onClick={onConfirmationConfirmed}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
