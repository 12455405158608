import { Fragment } from 'react';
import {
  List,
  Datagrid,
  EditButton,
  BulkDeleteWithConfirmButton,
  useRecordContext,
  WrapperField,
  SelectField,
  BooleanField,
  useGetList,
  Loading,
} from 'react-admin';
import { Chip } from '@mui/material';
import { TestTypeChoices } from 'types';

export const TestAdVideoPlaylistList = (props: any) => {
  const { data, isLoading } = useGetList('test-ad-video', {
    pagination: { page: 1, perPage: 5000 },
    sort: { field: 'Description', order: 'ASC' },
  });

  const BulkActionButtons = () => {
    return (
      <Fragment>
        <BulkDeleteWithConfirmButton
          mutationMode="pessimistic"
          confirmContent="Are you sure you want to delete the selected Test Ad Videos Playlist?"
        />
      </Fragment>
    );
  };

  const VideoDescriptions = () => {
    const record = useRecordContext();
    return (record.VideoIds || []).map((id: string) => {
      const video = data?.find((entry) => entry.id === id);
      return <Chip key={id} label={video.Description || ''} />;
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <List {...props} title="System Messages" pagination={false} perPage={1000}>
      <Datagrid bulkActionButtons={<BulkActionButtons />}>
        <SelectField source="TestType" label="Test Type" choices={TestTypeChoices} />
        <BooleanField source="IsEnabled" label="Is Enabled" />
        <WrapperField source="VideoIds" label="Video Playlist">
          <VideoDescriptions />
        </WrapperField>
        <EditButton />
      </Datagrid>
    </List>
  );
};
