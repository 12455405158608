import {
  DataProvider,
  fetchUtils,
  RaRecord,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
} from 'react-admin';
import httpClient from 'providers/httpClient';

const extractFileExtension = (fileName?: string): string => {
  const parts = (fileName || '').split('.') || [];
  const ext = parts.length > 0 ? parts[parts.length - 1] : '';
  return ext;
};

export const ResultPDFConfig_create = <RecordType extends RaRecord>(
  baseDataProvider: DataProvider,
  resource: string,
  params: CreateParams
): Promise<CreateResult<RecordType>> => {
  return new Promise<CreateResult<RecordType>>((resolve, reject) => {
    // send the request to the to create the initial record
    baseDataProvider
      .create(resource, params)
      .then((result) => {
        const id = result.data.id || '';
        return Promise.all([
          result,
          httpClient(
            `${process.env.REACT_APP_API_URL}/admin/result-pdf-config/${id}/logo-upload-url`,
            {
              method: 'GET',
            }
          ),
          httpClient(
            `${process.env.REACT_APP_API_URL}/admin/result-pdf-config/${id}/footer-image-upload-url`,
            {
              method: 'GET',
            }
          ),
        ]);
      })
      .then((results: any[]) => {
        const result = results[0];
        const promises = [result];
        const LogoImage = params?.data?.LogoImage || {};
        const FooterImage = params?.data?.LogoImage || {};

        let ext = extractFileExtension(LogoImage.title);
        let headers =
          ext.length > 0
            ? new Headers({
                'Content-Type': `image/${ext}`,
              })
            : undefined;

        let uploadUrl = (results[1]?.json || {}).uploadUrl;
        promises.push(
          fetchUtils.fetchJson(uploadUrl, {
            method: 'PUT',
            headers,
            body: LogoImage.rawFile,
          })
        );

        if (!!FooterImage.rawFile) {
          ext = extractFileExtension(FooterImage.title);
          headers =
            ext.length > 0
              ? new Headers({
                  'Content-Type': `image/${ext}`,
                })
              : undefined;

          uploadUrl = (results[1]?.json || {}).uploadUrl;
          promises.push(
            fetchUtils.fetchJson(uploadUrl, {
              method: 'PUT',
              headers,
              body: FooterImage.rawFile,
            })
          );
        }

        return Promise.all(promises);
      })
      .then((results: any[]) => {
        const result = results[0];
        resolve(result as CreateResult<RecordType>);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ResultPDFConfig_update = <RecordType extends RaRecord>(
  baseDataProvider: DataProvider,
  resource: string,
  params: UpdateParams
): Promise<UpdateResult<RecordType>> => {
  return new Promise<CreateResult<RecordType>>((resolve, reject) => {
    if (Array.isArray(params.data?.LogoImage)) {
      // convert the array of Images to a single image (occurs when the user does not change the logo image during the update)
      params.data.LogoImage = params.data.LogoImage[0];
    }

    if (Array.isArray(params.data?.FooterImage)) {
      // convert the array of Images to a single image (occurs when the user does not change the footer image during the update)
      params.data.FooterImage = params.data.FooterImage[0];
    }

    // send the request to the to create the initial record
    baseDataProvider
      .update(resource, params)
      .then((result) => {
        const id = result.data.id || '';
        return Promise.all([
          result,
          httpClient(
            `${process.env.REACT_APP_API_URL}/admin/result-pdf-config/${id}/logo-upload-url`,
            {
              method: 'GET',
            }
          ),
          httpClient(
            `${process.env.REACT_APP_API_URL}/admin/result-pdf-config/${id}/footer-image-upload-url`,
            {
              method: 'GET',
            }
          ),
        ]);
      })
      .then((results: any[]) => {
        const result = results[0];
        const LogoImage = params.data?.LogoImage || {};
        const FooterImage = params.data?.FooterImage || {};

        const promises = [result];

        if (!!LogoImage.rawFile) {
          // update the logo with the new file
          const ext = extractFileExtension(LogoImage.title);
          const headers =
            ext.length > 0
              ? new Headers({
                  'Content-Type': `image/${ext}`,
                })
              : undefined;

          const json = results[1]?.json || {};
          const uploadUrl = json.uploadUrl;

          const options = {
            method: 'PUT',
            headers,
            body: LogoImage.rawFile,
          };

          promises.push(fetchUtils.fetchJson(uploadUrl, options));
        }

        if (!!FooterImage.rawFile) {
          // update the footer image with the new file
          const ext = extractFileExtension(FooterImage.title);
          const headers =
            ext.length > 0
              ? new Headers({
                  'Content-Type': `image/${ext}`,
                })
              : undefined;

          const json = results[2]?.json || {};
          const uploadUrl = json.uploadUrl;

          const options = {
            method: 'PUT',
            headers,
            body: FooterImage.rawFile,
          };

          promises.push(fetchUtils.fetchJson(uploadUrl, options));
        }

        return Promise.all(promises);
      })
      .then((results: any[]) => {
        const result = results[0];
        resolve(result as UpdateResult<RecordType>);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const TestAdVideo_list = <RecordType extends RaRecord>(
  baseDataProvider: DataProvider,
  resource: string,
  params: GetListParams
): Promise<GetListResult<RecordType>> => {
  return new Promise<GetListResult<RecordType>>((resolve, reject) => {
    // send the request to the to create the initial record
    baseDataProvider
      .getList(resource, params)
      .then((results) => {
        const data = results.data || [];

        resolve({
          data: data.map((item) => ({
            ...item,
            name: item.Description,
          })),
          total: results.total,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
