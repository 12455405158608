import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  TextField,
  DateField,
  SimpleShowLayout,
} from 'react-admin';
import { DocumentLanguageChoices, DocumentTypeChoices } from '../../types';

export const DocumentEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SimpleShowLayout>
          <TextField source="ID" label="ID" />
          <DateField source="CreatedAt" label="Created At" showTime={true} />
          <DateField source="UpdatedAt" label="Updated At" showTime={true} />
        </SimpleShowLayout>
        <TextInput
          source="AppType"
          label="App ID / Bundle ID"
          required={true}
          style={{ width: '50%' }}
        />
        <SelectInput
          source="DocumentType"
          label="Document Type"
          required={true}
          choices={DocumentTypeChoices}
        />
        <TextInput source="Url" label="Document Url" required={true} fullWidth={true} />
        <TextInput source="Version" label="Document Version" required={true} />
        <SelectInput
          source="LanguageCode"
          label="Language Code"
          required={true}
          choices={DocumentLanguageChoices}
        />
      </SimpleForm>
    </Edit>
  );
};
