import React, { useState } from 'react';
import { SimpleForm, TextInput, SelectInput, FormDataConsumer, Create } from 'react-admin';
import { TestTypeChoices, EmailMessageTypeChoices, TestResultValueChoices } from '../../types';
import { validateSubject } from './validation';
import { Button } from '@mui/material';
import { HtmlPreviewDialog } from './HtmlPreviewDialog';

export const EmailMessageCreate = (props: any) => {
  const [showBodyHtmlPreview, setShowHtmlBodyPreview] = useState<boolean>(false);

  return (
    <Create {...props}>
      <SimpleForm shouldUnregister>
        <SelectInput
          source="TestType"
          label="Test Type"
          required={true}
          choices={TestTypeChoices}
        />
        <SelectInput
          source="MessageType"
          label="Email Message Type"
          required={true}
          choices={EmailMessageTypeChoices}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.MessageType === 'follow-up' && (
              <SelectInput
                source="TestResultValue"
                label="Test Result Value"
                choices={TestResultValueChoices}
              />
            )
          }
        </FormDataConsumer>
        <TextInput
          source="Subject"
          label="Email Subject"
          validate={validateSubject}
          fullWidth={true}
        />
        <TextInput
          source="BodyHtml"
          label="Email Body (Html)"
          multiline={true}
          hidden={false}
          minRows={10}
          maxRows={10}
          fullWidth={true}
          resettable={true}
        />
        <div style={{ marginBottom: 40 }}>
          <Button
            variant="contained"
            onClick={() => {
              setShowHtmlBodyPreview(true);
            }}
          >
            Show Html Body Preview
          </Button>
        </div>
        <TextInput
          source="BodyText"
          label="Email Body (Plain Text)"
          multiline={true}
          hidden={false}
          minRows={10}
          maxRows={10}
          fullWidth={true}
          resettable={true}
        />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <HtmlPreviewDialog
                visible={showBodyHtmlPreview}
                htmlText={formData.BodyHtml}
                onClose={() => {
                  setShowHtmlBodyPreview(false);
                }}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
