import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { DocumentLanguageChoices, DocumentTypeChoices } from '../../types';

export const DocumentCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="AppType"
        label="App ID / Bundle ID"
        required={true}
        style={{ width: '50%' }}
      />
      <SelectInput source="DocumentType" label="Document Type" choices={DocumentTypeChoices} />
      <TextInput source="Url" label="Document Url" required={true} fullWidth={true} />
      <TextInput source="Version" label="Document Version" required={true} />
      <SelectInput
        source="LanguageCode"
        label="Language Code"
        required={true}
        choices={DocumentLanguageChoices}
      />
    </SimpleForm>
  </Create>
);
